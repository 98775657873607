.Person {
  width: 60%;
  border: 3px solid #ccc;
  box-shadow: 2px 2px 3px #ededee; 
  margin: 16px auto;
  text-align: center;
  padding: 5px;
}

@media (min-width: 600px) {
  .Person {
    width: 450px;
  }
}